import React from 'react'
import styled from 'styled-components'
import tokens from '@te-digi/styleguide-tokens'

import { List, ListItem, Paragraph, Spacer, Stack } from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Placeholder } from '../../components/Placeholder'
import { Section } from '../../components/Section'

const SpacerWrapper = styled.div`
  > * > * > * {
    background-color: ${tokens.color['neutral-2']};
    border-radius: ${tokens.radius.md};
  }
`
// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Spacer"
    components={[{ component: Spacer, restElement: true }]}
  >
    <Section title="Padding / Margin">
      <List variant="unordered">
        <ListItem>
          <Code>Spacer</Code>-komponentilla voidaan määritellä{' '}
          <Code>margin</Code> tai <Code>padding</Code> arvot komponentin eri
          sivuille.
        </ListItem>
        <ListItem>
          Marginaalina voi käyttää myös oletusskaalan negatiivisia arvoja
          <Code>N-xs</Code>, <Code>N-sm</Code>, jne.).
        </ListItem>
        <ListItem>
          Arvon voi määritellä vaihtuvaksi eri kokoisilla näytöillä.
        </ListItem>
      </List>
      <Playground WrapperComponent={SpacerWrapper}>
        <Stack gap="md">
          <Spacer padding="lg">Lorem ipsum dolor</Spacer>
          <Spacer paddingTop="lg">Lorem ipsum dolor</Spacer>
          <Spacer paddingRight="lg">Lorem ipsum dolor</Spacer>
          <Spacer paddingBottom="lg">Lorem ipsum dolor</Spacer>
          <Spacer paddingLeft="lg">Lorem ipsum dolor</Spacer>
          <Spacer margin={{ xs: 'xxxs', sm: 'xxs', md: 0, lg: 'xxxl' }}>
            Lorem ipsum dolor
          </Spacer>
        </Stack>
      </Playground>
    </Section>
    <Section title="Width">
      <Paragraph>
        <Code>Spacer</Code>-komponentilla voi säätää sen sisälle aseteltavien
        komponenttien maksimileveyttä prosentuaalisilla arvoilla <Code>25</Code>
        , <Code>50</Code>, <Code>75</Code> tai <Code>100</Code>.
      </Paragraph>
      <Playground>
        <Stack gap="md">
          <Spacer width={{ xs: 25 }}>
            <Placeholder block />
          </Spacer>
          <Spacer width={{ xs: 50 }}>
            <Placeholder block />
          </Spacer>
          <Spacer width={{ xs: 75 }}>
            <Placeholder block />
          </Spacer>
          <Spacer width={{ xs: 100 }}>
            <Placeholder block />
          </Spacer>
        </Stack>
      </Playground>
    </Section>
  </Content>
)

export default Page
